/* Container */
.file-upload-container {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Title */
.file-upload-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Form */
.file-upload-form {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.file-upload-label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.file-upload-input,
.file-upload-file-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.file-upload-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background-color: #4CAF50;
  color: white;
  transition: background-color 0.3s;
}

.file-upload-button:hover {
  background-color: #45a049;
}

.file-upload-error,
.file-upload-success {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  font-weight: bold;
}

.file-upload-error {
  color: #f44336;
  background-color: #fdecea;
}

.file-upload-success {
  color: #4CAF50;
  background-color: #e8f5e9;
}

/* File List */
.file-upload-list {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.file-upload-list-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.file-upload-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.file-upload-table th,
.file-upload-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.file-upload-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.file-upload-table td {
  background-color: #fff;
}

.file-upload-download-button {
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background-color: #2196F3;
  color: white;
  transition: background-color 0.3s;
}

.file-upload-download-button:hover {
  background-color: #1976D2;
}
