@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

/* General page container */
.page-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

/* Header styling */
.header {
  width: 100%;
  background-color: #00509e;
  color: white;
  padding: 10px;
  display: flex;
  align-items: center;
}

.header-logo {
  display: flex;
  align-items: center;
}

.header-logo img.logo {
  height: 80px;
  /* Match the logo height from the dashboard */
  margin-right: 15px;
}

.header-text h1 {
  font-size: 24px;
  font-weight: normal;
  margin: 0;
}

/* Content area styling */
.content {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

/* Centered content for login */
.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
}

/* Login form styling */
.login-form {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}

.login-form h2 {
  margin-bottom: 20px;
}

.login-form div {
  margin-bottom: 15px;
  text-align: left;
}

.login-form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.login-form input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.login-form button {
  width: 100%;
  padding: 10px;
  background-color: #00509e;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.login-form button:hover {
  background-color: #003366;
}

/* Error message styling */
.error-message {
  color: red;
  margin-top: 10px;
}