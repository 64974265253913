/* Main Container */
.job-description {
  width: 100%;
  margin: 20px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Title Styling */
.imp-job-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

/* Row Styling */
.row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

/* Label and Input Styling */
.row label {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  color: #555;
  width: 30%;
}

.row input[type="text"],
.row input[type="number"],
.row input[type="date"],
.row select {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  margin-top: 5px;
}

/* Button Group */
.ref-no-group {
  display: flex;
  align-items: center;
}

.ref-no-group button {
  margin-left: 10px;
  padding: 8px 12px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.ref-no-group button:hover {
  background-color: #0056b3;
}

/* Shipping Details Title */
.row-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  color: #007bff;
  width: 100%;
  text-align: left;
}

/* Success and Error Messages */
.success-message {
  color: #28a745;
  font-size: 16px;
}

.error-message {
  color: #dc3545;
  font-size: 16px;
}

/* Buttons Styling */
.save-button, .reset-button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.save-button {
  background-color: #28a745;
  color: white;
}

.save-button:hover {
  background-color: #218838;
}

.reset-button {
  background-color: #ffc107;
  color: white;
  margin-left: 10px;
}

.reset-button:hover {
  background-color: #e0a800;
}

/* Additional Styles for Inputs and Labels */
.imp-job-input, .imp-job-select {
  font-size: 14px;
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.imp-job-label {
  font-size: 16px;
  color: #555;
  width: 30%;
}

.imp-job-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

/* Checkbox Styling */
.imp-job-checkbox {
  transform: scale(1.2);
  margin-top: 10px;
}

.imp-job-remark {
  width: 65%;
}

.imp-job-remark input {
  width: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .row {
      flex-direction: column;
      margin-bottom: 10px;
  }

  .row label {
      width: 100%;
  }

  .imp-job-remark {
      width: 100%;
  }
}
