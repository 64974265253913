/* Container */
.charges-master {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Title */
.charges-master-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Form */
.charges-master-form {
  margin-bottom: 30px;
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.charges-master-label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
}

.charges-master-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.charges-master-button-group {
  display: flex;
  gap: 10px;
}

.charges-master-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.charges-master-button.save {
  background-color: #4CAF50;
  color: white;
}

.charges-master-button.reset {
  background-color: #f44336;
  color: white;
}

.charges-master-message {
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  font-weight: bold;
}

.charges-master-message.error {
  color: #f44336;
  background-color: #fdecea;
}

.charges-master-message.success {
  color: #4CAF50;
  background-color: #e8f5e9;
}

/* Search Filters */
.charges-master-search-filters {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
}

.charges-master-search-input,
.charges-master-filter-dropdown {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.charges-master-button.search,
.charges-master-button.reset {
  background-color: #008CBA;
  color: white;
}

/* View Buttons */
.charges-master-view-buttons {
  margin-bottom: 20px;
}

.charges-master-view-button {
  padding: 8px 16px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  color: white;
  font-size: 14px;
  font-weight: bold;
}

.charges-master-view-button.active {
  background-color: green;
}

.charges-master-view-button.inactive {
  background-color: red;
}

/* Table */
.charges-master-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.charges-master-table th,
.charges-master-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.charges-master-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.charges-master-table td {
  background-color: #fff;
}

.charges-master-record-checkbox,
.charges-master-select-all-checkbox {
  cursor: pointer;
}

.charges-master-button.edit,
.charges-master-button.delete,
.charges-master-button.delete-permanent {
  padding: 6px 12px;
  margin-right: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.charges-master-button.edit {
  background-color: #ff9800;
  color: white;
}

.charges-master-button.delete {
  background-color: #f44336;
  color: white;
}

.charges-master-button.delete-permanent {
  background-color: #d32f2f;
  color: white;
}

.charges-master-button.bulk-delete {
  background-color: #f44336;
  color: white;
  margin-top: 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

/* No Records Message */
.charges-master-no-records-message {
  text-align: center;
  color: #777;
  margin-top: 20px;
  font-size: 16px;
}

/* Dialog */
.charges-master-dialog {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.charges-master-dialog-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.charges-master-dialog-text {
  margin-bottom: 20px;
  font-size: 16px;
}

.charges-master-button.confirm,
.charges-master-button.cancel {
  padding: 8px 16px;
  margin: 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.charges-master-button.confirm {
  background-color: #4CAF50;
  color: white;
}

.charges-master-button.cancel {
  background-color: #f44336;
  color: white;
}

.charges-master-button-search {
  background-color: #4CAF50;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}