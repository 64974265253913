.manage-users {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  text-align: center;
}

.register-form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.manage-users-form-group {
  display: flex;
  flex-direction: column;
}

label {
  font-weight: bold;
  color: #555;
  margin-bottom: 5px;
}

.manage-users-form-input {
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  transition: border-color 0.3s;
}

.manage-users-form-input:focus {
  border-color: #007BFF;
  outline: none;
}

.message {
  margin-top: 20px;
  padding: 10px;
  background-color: #e7f3fe;
  color: #31708f;
  border: 1px solid #bce8f1;
  border-radius: 4px;
}

.users-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 30px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.users-table th, .users-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.users-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.users-table td {
  background-color: #fff;
}

button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #e7f1ff;
  color: #0056b3;
  transition: background-color 0.3s;
  margin-bottom: 10px; /* Added margin for spacing */
}

button:hover {
  background-color: #cce5ff;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  z-index: 1000;
}

.modal h2 {
  margin-bottom: 15px;
  font-size: 1.5rem;
}

.modal select {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.manage-users-submit-button,
.users-table button {
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s, transform 0.2s;
    margin-right: 10px; /* Space between buttons */
}

.manage-users-submit-button {
    background-color: #007BFF; /* Primary button color */
    color: white;
}

.manage-users-submit-button:hover {
    background-color: #0056b3; /* Darker shade on hover */
    transform: translateY(-2px); /* Slight lift effect */
}

.users-table button {
    background-color: #6c757d; /* Secondary button color */
    color: white;
}

.users-table button:hover {
    background-color: #a1a7ab; /* Darker shade on hover */
    transform: translateY(-2px); /* Slight lift effect */
}

.modal button {
    background-color: #28a745; /* Green button for changing role */
    color: white;
    margin-right: 10px; /* Space between buttons */
}

.modal button:hover {
    background-color: #218838; /* Darker green on hover */
    transform: translateY(-2px); /* Slight lift effect */
}

.modal button:last-child {
    background-color: #dc3545; /* Red button for cancel */
}

.modal button:last-child:hover {
    background-color: #c82333; /* Darker red on hover */
}

/* Responsive button adjustment */
@media (max-width: 600px) {
    .manage-users-submit-button,
    .users-table button,
    .modal button {
        width: 100%; /* Full width on smaller screens */
        margin-bottom: 10px; /* Space between buttons */
    }
}