/* Container */
.gst-management-container {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Header */
.gst-management-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.gst-paid-status {
  background-color: #4CAF50; /* Green background */
  color: white;              /* White text */
  padding: 5px 10px;        /* Padding to give it a button-like appearance */
  border-radius: 4px;       /* Rounded corners */
  font-weight: bold;         /* Bold text */
  display: inline-block;     /* Make it inline-block for layout consistency */
  text-align: center;        /* Center align text */
  cursor: default;           /* Change cursor to indicate it’s not clickable */
}

/* Filters Section */
.gst-filters-section {
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

/* Filter Inputs and Selects */
.gst-filter-input,
.gst-filter-select {
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Button */
.gst-filter-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background-color: #4CAF50;
  color: white;
}

/* Error Message */
.gst-error-message {
  color: red;
  margin-top: 20px;
}

/* Table */
.gst-bill-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.gst-bill-table-head th,
.gst-bill-table-body td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.gst-bill-table-head th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.gst-bill-table-body td {
  background-color: #fff;
}

.gst-edit-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.gst-actions-buttons {
  display: flex;
  flex-wrap: wrap; /* Wrap buttons to the next line if necessary */
  gap: 4px; /* Adjust gap between buttons */
  max-width: 100%; /* Ensure buttons do not overflow */
  box-sizing: border-box; /* Ensure padding and border are included in width */
}

/* Styling individual buttons */
.gst-edit-button,
.gst-delete-button,
.gst-approve-button,
.gst-print-button,
.gst-save-button,
.gst-cancel-button {
  padding: 4px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px; /* Slightly smaller font size */
  font-weight: bold;
  white-space: nowrap; /* Prevent text from wrapping */
}

.gst-edit-button { background-color: #ffa500; color: white; }
.gst-delete-button { background-color: #f44336; color: white; }
.gst-approve-button { background-color: #4CAF50; color: white; }
.gst-print-button { background-color: #2196F3; color: white; }
.gst-save-button { background-color: #4CAF50; color: white; }
.gst-cancel-button { background-color: #f44336; color: white; }
