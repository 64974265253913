/* Container */
.bill-details-container {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Header */
.bill-details-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Filters Section */
.bill-details-filters {
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Filter Group */
.bill-details-filter-group {
  margin-bottom: 15px;
  display: flex; /* Align label and input in a row */
  align-items: center; /* Center align items vertically */
}

.bill-details-filter-group label {
  flex: 0 0 150px; /* Fixed width for labels */
  margin-right: 10px; /* Space between label and input */
  font-weight: 600; /* Bold labels */
}

/* Input and Select */
.bill-details-input,
.bill-details-select {
  width: 100%; /* Full width */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}


/* Actions */
.bill-details-actions {
  display: flex;
  gap: 10px;
}

/* Button */
.bill-details-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background-color: #4CAF50;
  color: white;
}

/* Summary */
.bill-details-summary {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

/* Table */
.bill-details-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.bill-details-table th,
.bill-details-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.bill-details-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.bill-details-view-button {
  padding: 4px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  background-color: #2196F3;
  color: white;
}
