.navbar {
  width: 100%;
  background-color: #003366;
  color: white;
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.navbar nav {
  display: flex;
  align-items: center; /* Ensure items are vertically centered */
}

.nav-item {
  position: relative;
  margin: 0 15px;
}

.nav-item button,
.nav-item a {
  background: none;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  text-decoration: none;
  padding: 10px 15px; /* Padding for better spacing */
  width: auto;
  text-align: center;
  display: flex;
  align-items: center; /* Center content within the button */
}

.nav-item button:hover,
.nav-item a:hover,
.nav-item button:focus,
.nav-item a:focus {
  background-color: #00509e;
  border-radius: 5px;
}

.dropdown {
  display: none;
  flex-direction: column;
  position: absolute;
  top: 100%; /* Position directly below the button */
  left: 0;
  background-color: #003366;
  z-index: 1;
  border: 1px solid #00509e;
  padding: 10px;
  min-width: 200px;
}

.dropdown a {
  color: white;
  text-decoration: none;
  padding: 10px;
  margin: 5px 0;
}

.dropdown a:hover {
  background-color: #00509e;
  border-radius: 5px;
}

/* Show dropdown on button hover */
.nav-item:hover .dropdown,
.nav-item:focus-within .dropdown {
  display: flex;
}
