/* Container */
.incomplete-jobs-container {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  max-width: 1600px;
  margin: 0 auto;
}

/* Title */
.incomplete-jobs-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Form */
.incomplete-jobs-form {
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap; /* Allow wrapping to next line if needed */
  gap: 20px;
}

/* Form Group */
.incomplete-jobs-form-group {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
}

/* Labels */
.incomplete-jobs-label {
  font-weight: bold;
}

/* Inputs and Dropdowns */
.incomplete-jobs-input,
.incomplete-jobs-dropdown {
  width: 200px; /* Fixed width for better alignment */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Button Group */
.incomplete-jobs-button-group {
  display: flex;
  justify-content: flex-end; /* Align button to the right */
  flex: 1;
}

.incomplete-jobs-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.incomplete-jobs-button-show {
  background-color: #4CAF50;
  color: white;
}

/* Table */
.incomplete-jobs-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.incomplete-jobs-table th,
.incomplete-jobs-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.incomplete-jobs-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.incomplete-jobs-table td {
  background-color: #fff;
}

/* No Jobs Message */
.incomplete-jobs-message {
  text-align: center;
  color: #666;
  font-size: 1.2em;
}
