/* Container */
.client-details-container {
  padding: 40px;
  background-color: #f5f5f5;
  border-radius: 8px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Title */
.client-details-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Search */
.client-details-search {
  margin-bottom: 20px;
}

.client-details-search-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

/* Client List */
.client-details-list {
  margin-bottom: 20px;
}

.client-details-item {
  padding: 10px;
  margin-bottom: 8px;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #ddd;
  transition: background-color 0.3s, box-shadow 0.3s;
}

.client-details-item:hover {
  background-color: #f0f0f0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Display */
.client-details-display {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.client-details-subtitle {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 20px;
}

.client-details-display p {
  margin: 8px 0;
  font-size: 14px;
}

.client-details-display strong {
  font-weight: bold;
}

/* Button */
.client-details-download {
  margin-top: 20px;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background-color: #4CAF50;
  color: white;
  transition: background-color 0.3s;
}

.client-details-download:hover {
  background-color: #45a049;
}
