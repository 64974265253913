.import-job-registration {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.import-job-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 15px;
}

.import-job-row-title {
    width: 100%;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
}

.import-job-row label {
    display: flex;
    flex-direction: column;
    flex: 1 1 calc(25% - 20px); 
    min-width: 200px;
}

.import-job-row label input,
.import-job-row label select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    margin-top: 5px;
}

.import-job-select-type {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    margin-top: 5px;
}

.ref-no-group {
    display: flex;
    align-items: center;
}

.ref-no-group input {
    flex: 1;
    margin-right: 10px;
}

.ref-no-group button {
    padding: 8px 12px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.ref-no-group button:hover {
    background-color: #0056b3;
}

.import-job-ref-button {
    padding: 8px 12px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.import-job-ref-button {
    background-color: #0056b3;
}

.import-job-details-button {
    padding: 10px 60px;
    border: none;
    background-color: #0088f8;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.import-job-details-button:hover {
    background-color: #0088f8;
}

.import-job-save-button,
.import-job-reset-button {
    padding: 10px 20px;
    border: none;
    background-color: #28a745;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
}

.import-job-reset-button {
    background-color: #dc3545;
}

.import-job-save-button:hover {
    background-color: #218838;
}

.import-job-reset-button:hover {
    background-color: #c82333;
}

.import-job-success-message,
.import-job-error-message {
    font-size: 1rem;
    margin-top: 10px;
}

.import-job-success-message {
    color: #28a745;
}

.import-job-error-message {
    color: #dc3545;
}

.error {
    color: red;                  /* Text color for error messages */
    font-size: 0.875em;          /* Slightly smaller font size */
    margin-top: 0.25em;          /* Space above the error message */
    margin-bottom: 1em;          /* Space below the error message */
}