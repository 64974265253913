/* General page styling */
.dashboard-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f0f0f0;
}

/* Header */
.dashboard-header {
  /* background-color: #ecebeb; */
  color: rgb(0, 0, 0);
  padding: 20px;
  text-align: center;
  justify-content: center;
}

/* Main Content */
.dashboard-main {
  flex: 1;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

html,body
{
   width: 100%;
   height: 100%;
   margin: 0px;
   padding: 0px;
   overflow-x: hidden;
}