/* Container */
.job-view-container {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
    margin: 0 auto;
  }
  
  /* Header */
  .job-view-container h1 {
    font-size: 24px;
    font-weight: bold;
    border-color: black;
  }
  
  /* Columns */
  .job-view-columns {
    display: flex;
    justify-content: space-between;
  }
  
  /* Column */
  .job-view-column {
    flex: 1;
    margin: 0 10px; /* Space between columns */
    min-width: 300px; /* Prevent columns from being too narrow */
  }
  
  /* Section Title */
  .job-view-column h2 {
    font-size: 20px;
    margin-top: 30px;
    margin-bottom: 15px;
  }
  
  /* Job View Table */
  .job-view-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #fff;
  }
  
  .job-view-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  .job-view-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .job-view-table td:first-child {
    font-weight: bold;
  }
  