/* General Styles */
body {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

/* Export Job Registration Styles */
.export-job-registration {
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.job-form-title {
  margin-bottom: 20px;
  text-align: center;
}

.job-form-title h1 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}

.form-group textarea {
  resize: vertical;
  height: 100px;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.form-row .form-group {
  flex: 1;
}

.form-actions {
  margin-top: 20px;
  text-align: right;
}

.form-actions button {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
}

.form-actions button[type="button"] {
  background-color: #007bff;
  color: #fff;
}

.form-actions button[type="button"].cancel {
  background-color: #dc3545;
}

.form-actions button[type="button"].close {
  background-color: #6c757d;
}

/* Popup Styles */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-inner {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: relative;
}

.popup .form-header h4 {
  margin-top: 0;
  font-size: 20px;
  color: #333;
}

.popup .form-actions {
  margin-top: 20px;
  text-align: right;
}

.popup .form-actions button {
  margin-left: 10px;
}

.popup .form-actions button.close {
  background-color: #6c757d;
  color: #fff;
}

/* Miscellaneous */
input[type="checkbox"] {
  transform: scale(1.2);
  margin-right: 10px;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
