/* Container */
.bill-tax {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Title */
  .bill-tax-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Form */
  .bill-tax-form {
    margin-bottom: 30px;
    background-color: #fff;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .bill-tax-label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .bill-tax-input {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .bill-tax-button-group {
    display: flex;
    gap: 10px;
  }
  
  .bill-tax-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  
  .bill-tax-button.save {
    background-color: #4CAF50;
    color: white;
  }
  
  .bill-tax-button-search {
    background-color: #4CAF50;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  
  .bill-tax-button.reset {
    background-color: #f44336;
    color: white;
  }
  
  .bill-tax-message {
    margin-top: 10px;
    padding: 10px;
    border-radius: 4px;
    font-weight: bold;
  }
  
  .bill-tax-message.error {
    color: #f44336;
    background-color: #fdecea;
  }
  
  .bill-tax-message.success {
    color: #4CAF50;
    background-color: #e8f5e9;
  }
  
  /* Search Filters */
  .bill-tax-search-filters {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .bill-tax-search-input,
  .bill-tax-filter-dropdown {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .bill-tax-button.search,
  .bill-tax-button.reset {
    background-color: #008CBA;
    color: white;
  }
  
  /* View Buttons */
  .bill-tax-view-buttons {
    margin-bottom: 20px;
  }
  
  .bill-tax-view-button {
    padding: 8px 16px;
    margin-right: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    font-size: 14px;
    font-weight: bold;
  }
  
  .bill-tax-view-button.active {
    background-color: green;
  }
  
  .bill-tax-view-button.inactive {
    background-color: red;
  }
  
  /* Table */
  .bill-tax-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
  }
  
  .bill-tax-table th,
  .bill-tax-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .bill-tax-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .bill-tax-table td {
    background-color: #fff;
  }
  
  .bill-tax-record-checkbox,
  .bill-tax-select-all-checkbox {
    cursor: pointer;
  }
  
  .bill-tax-button.edit,
  .bill-tax-button.delete,
  .bill-tax-button.delete-permanent {
    padding: 6px 12px;
    margin-right: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .bill-tax-button.edit {
    background-color: #ff9800;
    color: white;
  }
  
  .bill-tax-button.delete {
    background-color: #f44336;
    color: white;
  }
  
  .bill-tax-button.delete-permanent {
    background-color: #d32f2f;
    color: white;
  }
  
  .bill-tax-button.bulk-delete {
    background-color: #f44336;
    color: white;
    margin-top: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  
  /* No Records Message */
  .bill-tax-no-records-message {
    text-align: center;
    color: #777;
    margin-top: 20px;
    font-size: 16px;
  }
  
  /* Dialog */
  .bill-tax-dialog {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .bill-tax-dialog-content {
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .bill-tax-dialog-text {
    margin-bottom: 20px;
    font-size: 16px;
  }
  
  .bill-tax-button.confirm,
  .bill-tax-button.cancel {
    padding: 8px 16px;
    margin: 5px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  
  .bill-tax-button.confirm {
    background-color: #4CAF50;
    color: white;
  }
  
  .bill-tax-button.cancel {
    background-color: #f44336;
    color: white;
  }
  