.search {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .search h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .search-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .search-form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 33px;
  }
  
  .search-form-group {
    display: flex;
    flex-direction: column;
    flex: 1 1 30%;
    min-width: 200px;
  }
  
  .search-form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .search-form-group input,
  .search-form-group select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .search-button-group,
  .search-export-group {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
  }
  
  .search-button-group button,
  .search-export-group button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .search-button-group button:hover,
  .search-export-group button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .search-form-row {
      flex-direction: column;
    }
    
    .search-form-group {
      flex: 1 1 100%;
    }
  }
  