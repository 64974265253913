.header {
  display: flex;
  justify-content: space-between;
  margin: 0%;
  align-items: center;
  padding: 10px 20px;
  background-color: #003366;
  color: white;
  height: 100px; 
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 60px; 
  margin-right: 10px;
}

.welcome {
  font-size: 14px;
  text-align: center;
  color: red; /* Red text color for welcome message */
}

html,body
{
   width: 100%;
   height: 100%;
   margin: 0px;
   padding: 0px;
   overflow-x: hidden;
}
