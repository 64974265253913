/* Container */
.bill-view-table-container {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Header */
  .bill-view-table-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Table */
  .bill-view-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    background-color: #fff;
  }
  
  /* Table Header */
  .bill-view-table th,
  .bill-view-table td {
    border: 1px solid #ccc;
    padding: 10px;
    text-align: left;
  }
  
  /* Table Header */
  .bill-view-table th {
    background-color: #f0f0f0;
    font-weight: bold;
  }
  
  .bill-view-summary {
    margin-top: 20px;
    padding: 15px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .bill-view-summary h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
  
  /* Summary Table */
  .bill-summary-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .bill-summary-table td {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .bill-summary-table tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  .bill-summary-table td:first-child {
    font-weight: bold;
  }
  