/* Container */
.all-voucher-container {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  max-width: 1800px;
  margin: 0 auto;
}

/* Title */
.all-voucher-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Form */
.all-voucher-form {
  margin-bottom: 30px;
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
}

/* Form Group */
.all-voucher-form-group {
  display: flex;
  flex-direction: column;
}

/* Labels */
.all-voucher-label {
  margin-bottom: 8px;
  font-weight: bold;
}

/* Inputs and Dropdowns */
.all-voucher-input,
.all-voucher-dropdown {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Button Group */
.all-voucher-button-group {
  grid-column: span 2; 
  display: flex;
  justify-content: flex-end; 
  gap: 10px;
}

.all-voucher-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.all-voucher-button-show {
  background-color: #4CAF50;
  color: white;
}


/* Table */
.all-voucher-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.all-voucher-table th,
.all-voucher-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.all-voucher-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.all-voucher-table td {
  background-color: #fff;
}
