/* Container */
.voucher-payment-container {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  max-width: 1800px;
  margin: 0 auto;
}

/* Form */
.voucher-payment-form {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

/* Form Group */
.form-group {
  margin-bottom: 15px;
}

/* Input and Select */
.voucher-payment-input,
.voucher-payment-select {
  width: 200px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Submit Button */
.voucher-payment-btn-submit {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  background-color: #4CAF50;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

/* Loading and Error Message */
.voucher-payment-loading,
.voucher-payment-error-message {
  margin-top: 20px;
  color: red;
}

/* Table */
.voucher-payment-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.voucher-payment-table th,
.voucher-payment-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.voucher-payment-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

/* Summary Section */
.voucher-payment-summary {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }

/* Payment Button */
.voucher-payment-btn-pay {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s;
}

.voucher-payment-btn-pay:hover {
  background-color: #45a049;
}
