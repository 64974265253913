
.export-job-registration {
    padding: 20px;
    font-family: Arial, sans-serif;
}

.job-form-title h1 {
    margin-bottom: 20px;
}

.upd-form-group {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.upd-form-group label {
    margin-right: 10px;
}

.upd-form-group input[type="text"],
.upd-form-group input[type="date"] {
    margin-right: 10px;
    padding: 5px;
}

.upd-record-list {
    list-style-type: none;
    padding: 0;
}

.upd-record-list li {
    cursor: pointer;
    margin: 5px 0;
    padding: 10px;
    border: 1px solid #ddd;
}

.upd-record-list li:hover {
    background-color: #f0f0f0;
}

.upd-form-container {
    margin-top: 20px;
}

.upd-form-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
}

.upd-form-field {
    flex: 1 1 calc(25% - 20px);
    margin-right: 15px;
}

.upd-form-field label {
    display: block;
    margin-bottom: 5px;
}

.upd-form-field input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
}

.upd-doc-details,
.upd-clearance-details {
    margin-top: 20px;
}

.upd-popup-actions {
    margin-top: 10px;
}

.upd-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.upd-popup-inner {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 600px;
}

.upd-popup-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.upd-popup-header h4 {
    margin: 0;
}

.upd-popup-content {
    max-height: 800px;
    overflow-y: auto;
}

.upd-form-actions {
    margin-top: 20px;
}

.upd-form-actions button {
    margin-right: 10px;
    padding: 10px 20px;
}
