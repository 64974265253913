/* Container */
.voucher-entry {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  max-width: 1200px;
  margin: 0 auto;
}

/* Type Selector */
.voucher-entry-type {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Label for Select Type */
.voucher-entry-type label {
  display: block;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
}

/* Select Dropdown */
.voucher-entry-type select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
  color: #333;
}


/* Title */
.voucher-entry-title {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Form */
.voucher-form {
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
}

/* Form Group */
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.form-group label {
  margin-bottom: 8px;
  font-weight: bold;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}


/* Two Columns Layout */
.voucher-form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.form-group {
  flex: 1 1 calc(50% - 20px); /* Two columns with space between */
  min-width: 250px;
}

/* Buttons */
.button-group {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.button-group button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.button-group .save {
  background-color: #4CAF50;
  color: white;
}

.button-group .cancel {
  background-color: #f44336;
  color: white;
}

.fetch-details {
  background-color: #008CBA;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}
