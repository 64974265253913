
.profile-container {
    width: 500px;
    max-width: 500px;
    margin: 0 auto;
    background-color: #f5f5f5;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  h2 {
    text-align: center;
    margin-bottom: 20px;
  }

  .profile-form {
    display: flex;
    flex-direction: column;
  }
  
  .profile-input-group {
    margin-bottom: 15px;
  }
  
  .profile-input-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
  }
  
  .profile-details {
    margin-top: 20px;

  }

  .profile-input-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .profile-message {
    margin-top: 10px;
    color: #f44336;
  }
  
  .profile-submit-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
  }
  
  .profile-submit-button:hover {
    background-color: #45a049;
  }
  