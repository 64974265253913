/* Container */
.rate-master {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Title */
  .rate-master-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Client Selection */
  .rate-master-client-selection {
    margin-bottom: 20px;
  }
  
  .rate-master-label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .rate-master-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Table */
  .rate-master-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .rate-master-table th,
  .rate-master-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .rate-master-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .rate-master-table td {
    background-color: #fff;
  }
  
  .rate-master-table input[type="text"] {
    width: 100%;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .rate-master-table input[type="checkbox"] {
    cursor: pointer;
  }
  
  /* Buttons */
  .rate-master-buttons {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  
  .rate-master-button {
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  
  .rate-master-button.save {
    background-color: #4CAF50;
    color: white;
  }
  
  .rate-master-button.cancel {
    background-color: #f44336;
    color: white;
  }
  