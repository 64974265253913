/* Container */
.bill-payments-container {
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 8px;
  max-width: 1900px;
  margin: 0 auto;
}

/* Header */
.bill-payments-header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Form Styles */
.bill-payments-form {
  margin-bottom: 30px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.bill-pay-form-group {
  margin-bottom: 15px;
}

/* Inputs and Selects */
.bill-payments-input,
.bill-payments-select,
.bill-payments-edit-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Button */
.bill-payments-submit-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background-color: #4CAF50;
  color: white;
}

/* Loading and Error Messages */
.bill-payments-loading {
  color: #2196F3;
  margin-top: 20px;
}

.bill-payments-error {
  color: red;
  margin-top: 20px;
}

/* Table Styles */
.bill-payments-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.bill-payments-table th,
.bill-payments-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.bill-payments-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.bill-payments-table td {
  background-color: #fff;
}

/* Save Button */
.bill-payments-save-button {
  padding: 4px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: bold;
  background-color: #4CAF50;
  color: white;
}
