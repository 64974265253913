/* Container */
.rate-details {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  /* Title */
  .rate-details-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Client Selection */
  .rate-details-client-selection {
    margin-bottom: 20px;
  }
  
  .rate-details-label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
  }
  
  .rate-details-select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Table */
  .rate-details-table {
    width: 100%;
    border-collapse: collapse;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px;
  }
  
  .rate-details-table th,
  .rate-details-table td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  .rate-details-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .rate-details-table td {
    background-color: #fff;
  }
  
  .rate-details-table input[type="text"] {
    width: 100%;
    padding: 6px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .rate-details-table input[type="checkbox"] {
    cursor: pointer;
  }
  
  .rate-details-table button {
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  
  .rate-details-button.save {
    background-color: #4CAF50;
    color: white;
  }
  
  .rate-details-button.edit {
    background-color: #ff9800;
    color: white;
  }

  .rate-details-button-pdf {
    background-color: #4CAF50;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }

  .rate-details-button-xlx {
    background-color: #4CAF50;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }

  .rate-button-container {
    display: flex;
    gap: 10px;
  }
  