.login-page {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
}

.login-container {
  display: flex;
  width: 70%;
  max-width: 1200px;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  min-height: 60vh;
}

.login-image {
  width: 50%;
}

.login-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.login-form-container {
  width: 50%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.login-header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.header-logo {
  display: flex;
  align-items: center;
}

.header-logo .logo {
  width: 90px;
  margin-right: 10px;
}

.header-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.login-form h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

.login-form input {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.login-form button {
  width: 100%;
  padding: 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-form button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  font-size: 0.9rem;
  margin-bottom: 15px;
}
