/* Styling for Bond History Details Report */
.bond-history-details-report {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .bond-history-details-report h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .bond-history-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .bond-history-form-group {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .bond-history-form-group label {
    font-weight: bold;
    margin-right: 10px;
    width: 150px; /* Adjust as needed */
    color: #333;
  }
  
  .bond-history-form-group input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex: 1;
    min-width: 150px; /* Ensure consistent width */
  }
  
  .bond-history-button-group {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  
  .bond-history-button-group button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .bond-history-button-group button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 800px) {
    .bond-history-form {
      flex-direction: column;
    }
  }
  
  @media (max-width: 600px) {
    .bond-history-form-group {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .bond-history-form-group label {
      margin-bottom: 5px;
    }
  
    .bond-history-button-group {
      flex-direction: column;
      gap: 5px;
    }
  }
  