.manage-roles {
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.manage-roles-title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
}

.manage-roles-subtitle {
    font-size: 20px;
    margin: 15px 0;
    color: #555;
}

.add-role {
    margin-bottom: 30px;
    padding: 15px;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.manage-roles-form-input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.routes-selection {
    margin-bottom: 15px;
}

.checkbox-group {
    height: fit-content;
    overflow-y: auto;
}

.category-section {
    margin-bottom: 10px;
}

.category-header {
    display: flex;
    align-items: center;
}

.category-header input {
    margin-right: 8px;
}

.checkbox-item {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.checkbox-item input {
    margin-right: 8px;
}

.manage-roles-button {
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
}

.add-button {
    background-color: #28a745;
    color: #fff;
}

.edit-button {
    background-color: #007bff;
    color: #fff;
}

.cancel-button {
    background-color: #dc3545;
    color: #fff;
    margin-left: 10px;
}

.roles-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.roles-table th, .roles-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
}

.roles-table th {
    background-color: #f2f2f2;
}

.role-item {
    transition: background-color 0.3s;
}

.role-item:hover {
    background-color: #f1f1f1;
}

.role-actions {
    display: flex;
    gap: 10px;
}

.edit-role-button {
    background-color: #ff9800;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.delete-role-button {
    background-color: #dc3545;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.message {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    color: #333;
    background-color: #e9ecef;
}



.backdrop {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;

    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;

    display: flex;
    align-items: center;
    justify-content: center;
}



.add-role {
    width: 80%;
    height: fit-content;
    padding: 20px;
    display:flex;
    flex-direction: column;
}


.routes-selection {
    height: fit-content;
}

.routes-selection h3 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;

}

.access-selection {
    display: flex;
    width: 100%;
    justify-content: space-around;

    height: fit-content;
}


.managebuttons {
    display: flex;
    justify-content: flex-end;
    gap: 7px;
}