.view-log {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .view-log h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .view-log-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .view-log-form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .view-log-form-group {
    display: flex;
    flex-direction: column;
    flex: 1 1 30%;
    min-width: 200px;
  }
  
  .view-log-form-group label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .view-log-form-group input,
  .view-log-form-group select,
  .view-log-form-group button {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
  }
  
  .view-log-form-group button {
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .view-log-form-group button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .view-log-form-row {
      flex-direction: column;
    }
    
    .view-log-form-group {
      flex: 1 1 100%;
    }
  }
  