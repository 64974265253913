/* Container */
.main-bill-billing-page {
  padding: 20px; /* Keep original padding */
  background-color: #f5f5f5;
  border-radius: 8px;
  max-width: 1200px; /* Set max-width to a reasonable size */
  margin: 0 auto;
}

/* Title */
.main-bill-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Form Container */
.main-bill-form-container {
  background-color: #fff;
  padding: 20px; /* Keep original padding */
  border-radius: 8px;
  max-width: 100%; /* Allow it to take full width */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Form */
.main-bill-form {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Increased gap between fields */
}

.main-bill-form-group {
  display: flex;
  flex-wrap: wrap; 
  margin-bottom: 15px;
}

/* Update the child elements of .main-bill-form-group to take 50% width */
.main-bill-form-group > * {
  flex: 0 0 48%; /* Each item takes up approximately 48% of the width for better spacing */
  box-sizing: border-box; /* Ensures padding and borders are included in the total width */
}

/* Adjust the labels to make sure they are clear in a two-column layout */
.main-bill-label {
  font-weight: bold;
  margin-bottom: 8px;
  display: block; /* Ensure labels take the full width */
}

/* Inputs and Selects */
.main-bill-input,
.main-bill-select {
  width: 100%; /* Keep width at 100% */
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Buttons */
.main-bill-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  background-color: #4CAF50;
  color: white;
}

.main-bill-popup-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: white;
  gap: 8px;
  margin-right: 10px;
}

.main-bill-popup-save-button {
  background-color: #4CAF50;
}

.main-bill-popup-cancel-button {
  background-color: #f44336;
  margin-right: 0;
}

/* Popup */
.main-bill-description-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.main-bill-popup-title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
}

/* Table */
.main-bill-description-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
}

.main-bill-description-table th,
.main-bill-description-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.main-bill-description-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.main-bill-description-table td {
  background-color: #fff;
}

/* Summary Section */
.main-bill-summary {
  margin-top: 30px;
}

.main-bill-description-title {
  font-size: 20px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Adjust Button Width */
.main-bill-form-group:last-child {
  display: flex;
  justify-content: flex-end;
}
