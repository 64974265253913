/* Styling for Company Report */
.company-report {
    padding: 20px;
    max-width: 900px;
    margin: 0 auto;
  }
  
  .company-report h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .company-report-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .company-report-form-group {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .company-report-form-group label {
    font-weight: bold;
    margin-right: 10px;
    width: 100px; /* Adjust as needed */
    color: #333;
  }
  
  .company-report-form-group input,
  .company-report-form-group select {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    flex: 1;
    min-width: 150px; /* Ensure consistent width */
  }
  
  .company-report-button-group {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
  }
  
  .company-report-button-group button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .company-report-button-group button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 800px) {
    .company-report-form {
      flex-direction: column;
    }
  }
  
  @media (max-width: 600px) {
    .company-report-form-group {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .company-report-form-group label {
      margin-bottom: 5px;
    }
  
    .company-report-button-group {
      flex-direction: column;
      gap: 5px;
    }
  }
  